import { createAction, createReducer } from 'redux-starter-kit';
import {
  applyFilterDistributors,
  distributorInRange,
  sortDisplayedDistributors,
  getSortedDistributorsByDistance,
  sortDistributorsWithSearch
} from '../components/utils';
import { searchLocation } from './search';
import { config } from '../config';

const loadDistributorsAction = createAction('distributors/loadDistributors');
const chooseDistributorAction = createAction('distributors/chooseDistributor');
const routeToDistributorAction = createAction('distributors/routeToDistributor');
const toDisplayAction = createAction('distributors/toDisplay');
const closestToDisplayAction = createAction('distributors/closestToDisplay');
const updateDistributorsAction = createAction('distributors/updateDistributors');
const updateDistributorsBasicAction = createAction('distributors/updateDistributorsBasic');
const filterDistributorsAction = createAction('distributors/filterDistributors');
const clearDistributorSearchAction = createAction('distributors/clearDistributorSearch');
const resetZoomAndCenterToInit = createAction('distributors/resetZoomAndCenter');

/**
 *
 * @param {Distributor[]} distributors
 * @param {Distributor[]} distributorsFiltered
 * @param {Distributor[]} distributorsToDisplay
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const updateDistributors = (distributors, distributorsFiltered, distributorsToDisplay) => {
  return dispatch => {
    dispatch(
      updateDistributorsAction({ distributors, distributorsFiltered, distributorsToDisplay })
    );
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const updateDistributorsBasic = distributors => {
  return dispatch => {
    dispatch(updateDistributorsAction({ distributors }));
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const filterDistributors = (distributors, filterOptions) => {
  return dispatch => {
    dispatch(filterDistributorsAction(applyFilterDistributors(distributors, filterOptions)));
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const loadDistributors = (
  distributors,
  distributorsFiltered,
  newBorders,
  chosenDistributor = {}
) => {
  return dispatch => {
    dispatch(
      loadDistributorsAction({ distributors, distributorsFiltered, newBorders, chosenDistributor })
    );
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const chooseDistributor = distributor => {
  return dispatch => {
    dispatch(chooseDistributorAction(distributor));
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const clearChooseDistributor = () => {
  return (dispatch, getState) => {
    const {
      distributors: { chosenDistributor }
    } = getState();

    if (chosenDistributor && Object.keys(chosenDistributor).length) {
      dispatch(chooseDistributorAction({}));
    }
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const routeToDistributor = distributor => {
  return dispatch => {
    dispatch(routeToDistributorAction(distributor));
  };
};

/**
 * @param {Distributor[]} distributorsFiltered
 * @param {Borders} borders
 * @param {[number, number]} center
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const toDisplay = (distributorsFiltered, borders, center) => {
  return (dispatch, getState) => {
    const distributorsToDisplay = distributorsFiltered.filter(distributor =>
      distributorInRange(distributor.path, borders)
    );

    const {
      search: { searchResults }
    } = getState();

    if (searchResults?.length) {
      distributorsToDisplay.sort(sortDistributorsWithSearch);
    } else {
      distributorsToDisplay.sort(sortDisplayedDistributors);
    }

    /** @type {Borders} */
    const newBorders = {
      _southWest: {
        lat: borders._southWest.lat,
        lng: borders._southWest.lng
      },
      _northEast: {
        lat: borders._northEast.lat,
        lng: borders._northEast.lng
      }
    };

    let newCenter = null;
    if (center) {
      newCenter = [center.lat, center.lng];
    }

    dispatch(toDisplayAction({ distributorsToDisplay, newBorders, newCenter }));
  };
};

/**
 * @param {Distributor[]} distributors
 * @param {distributor[]} distributorsFiltered
 * @param {SearchResult[]} searchResults
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const closestToDisplay = (distributors, distributorsFiltered, searchResults) => {
  return dispatch => {
    if (searchResults?.length) {
      const distributorsDistance = getSortedDistributorsByDistance(
        distributors,
        searchResults[0].center
      );

      const distributorsFilteredDistance = getSortedDistributorsByDistance(
        distributorsFiltered,
        searchResults[0].center
      );

      const distributorsDistanceClosest = distributorsFilteredDistance.slice(0, 10);

      dispatch(
        closestToDisplayAction({
          distributorsDistance,
          distributorsFilteredDistance,
          distributorsDistanceClosest
        })
      );
    }
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const clearDistributorSearch = () => {
  return dispatch => {
    dispatch(clearDistributorSearchAction({}));
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const resetZoomAndCenter = () => {
  return dispatch => {
    dispatch(resetZoomAndCenterToInit());
  };
};

/**
 * @param {[number, number]} coords
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const setAndShowSelfCoordinates = coords => {
  return (dispatch, getState) => {
    const searchValue = 'Mich';
    const searchResults = [
      {
        place_name_de: searchValue,
        center: coords
      }
    ];

    const {
      distributors: { chosenDistributor, distributors, distributorsFiltered }
    } = getState();

    if (chosenDistributor?.Name) {
      dispatch(clearDistributorSearch());
    }

    dispatch(searchLocation(searchResults, searchValue));
    dispatch(closestToDisplay(distributors, distributorsFiltered, searchResults));
  };
};

/** @type {import('redux').Reducer<DistributorsStore>} */
const distributorsReducer = createReducer(
  {
    distributors: [],
    distributorsFiltered: [],
    distributorsToDisplay: [],
    distributorsClosest: [],
    chosenDistributor: {},
    routeToDistributor: {},
    borders: {},
    prevBorders: {},
    center: null,
    prevCenter: null
  },
  {
    [loadDistributorsAction]: (state, action) => {
      return {
        ...state,
        distributors: action.payload.distributors,
        distributorsFiltered: action.payload.distributorsFiltered,
        distributorsToDisplay: action.payload.distributorsFiltered,
        borders: action.payload.newBorders,
        initBorders: action.payload.newBorders,
        chosenDistributor: action.payload.chosenDistributor
      };
    },
    [updateDistributorsAction]: (state, action) => {
      return {
        ...state,
        distributors: action.payload.distributors,
        distributorsFiltered: action.payload.distributorsFiltered,
        distributorsToDisplay: action.payload.distributorsToDisplay
      };
    },
    [updateDistributorsBasicAction]: (state, action) => {
      return {
        ...state,
        distributors: action.payload.distributors
      };
    },
    [filterDistributorsAction]: (state, action) => {
      return {
        ...state,
        distributorsFiltered: action.payload
      };
    },
    [chooseDistributorAction]: (state, action) => {
      /* const distributorsToDisplay = [...state.distributorsToDisplay];
      const chosenDistributor = action.payload;
      if (chosenDistributor && Object.keys(chosenDistributor).length) {
        const distributorIdxInArr = distributorsToDisplay.findIndex(distributor => {
          return distributor.index === chosenDistributor.index;
        });
        if (distributorIdxInArr !== -1) {
          const [removedDistributor] = distributorsToDisplay.splice(distributorIdxInArr, 1);
          distributorsToDisplay.unshift(removedDistributor);
        }
      } */

      return {
        ...state,
        chosenDistributor: action.payload
      };
    },
    [clearDistributorSearchAction]: state => {
      return {
        ...state,
        routeToDistributor: {},
        chosenDistributor: {}
      };
    },
    [routeToDistributorAction]: (state, action) => {
      return {
        ...state,
        routeToDistributor: action.payload
      };
    },
    [toDisplayAction]: (state, action) => {
      const { newBorders, newCenter, distributorsToDisplay } = action.payload;
      /* const distributorsToDisplay = [...action.payload.distributorsToDisplay];
      const { chosenDistributor } = state;

      if (chosenDistributor && Object.keys(chosenDistributor).length) {
        const distributorIdxInArr = distributorsToDisplay.findIndex(distributor => {
          return distributor.index === chosenDistributor.index;
        });
        if (distributorIdxInArr !== -1) {
          const [removedDistributor] = distributorsToDisplay.splice(distributorIdxInArr, 1);
          distributorsToDisplay.unshift(removedDistributor);
        }
      } */

      return {
        ...state,
        distributorsToDisplay,
        borders: newBorders,
        center: newCenter || state.center
      };
    },
    [closestToDisplayAction]: (state, action) => {
      return {
        ...state,
        distributors: action.payload.distributorsDistance,
        distributorsFiltered: action.payload.distributorsFilteredDistance,
        distributorsClosest: action.payload.distributorsDistanceClosest
      };
    },
    [resetZoomAndCenterToInit]: state => {
      return {
        ...state,
        borders: state.initBorders,
        prevBorders: state.initBorders,
        center: config.defaultMapCenter
      };
    }
  }
);

export {
  distributorsReducer,
  loadDistributors,
  chooseDistributor,
  clearChooseDistributor,
  toDisplay,
  closestToDisplay,
  updateDistributors,
  updateDistributorsBasic,
  filterDistributors,
  routeToDistributor,
  clearDistributorSearch,
  setAndShowSelfCoordinates,
  resetZoomAndCenter
};
