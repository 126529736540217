export const config = {
  apiUrl: 'https://filialfinder.airliquide.at/api',
  mapBoxKey:
    'pk.eyJ1IjoiYWlybGlxdWlkZWF1c3RyaWEiLCJhIjoiY2thNnF4cnRpMDlrajJxb2hwNHh6Z3RwaCJ9.Nb9p8iTcX2azngxr7HJ4iQ',
  mapBoxStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
  defaultMapCenter: [47.6986, 13.2989]
};

export const mapBoxUrl = (searchValue, limit = 10) => {
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchValue}.json?limit=${limit}&access_token=${config.mapBoxKey}&country=AT&language=de`;
};
