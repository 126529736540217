import React from 'react';
import { IconSVG } from '../IconsSVG';
import Loader from '../Loader';
import './addSearch.scss';

const AdditionalSearchOptions = React.lazy(() => import('./AdditionalSearchOptions'));
const searchOptionsLoader = (
  <div className="add-search__loading">
    <Loader />
  </div>
);

function AdditionalSearch({
  companiesVisible,
  toggleCompanies,
  searchOptionsVisible,
  toggleOptions,
  // applyFilter,
  filter,
  filterOn,
  clearFilter,
  changeOptionHandler
}) {
  const isMobile = document.documentElement.clientWidth <= 768;

  return (
    <div className="add-search">
      {isMobile && (
        <>
          <label
            className={`add-search__icon add-search__icon--alt ${
              !companiesVisible ? 'add-search__icon--rotate' : ''
            }`}
            htmlFor="showCompanies"
          >
            <IconSVG type="arrow" />
          </label>
          <button
            className="add-search__button add-search__button--right"
            id="showCompanies"
            onClick={toggleCompanies}
          >
            {companiesVisible ? 'Zweige verstecken' : 'Zweige zeigen'}
          </button>
        </>
      )}
      <div className="add-search__control">
        <div className="add-search__control-group">
          <label
            className={`add-search__icon ${
              searchOptionsVisible ? 'add-search__icon--alt' : 'add-search__icon--plus'
            }`}
            htmlFor="add-search__button"
          >
            <IconSVG type={searchOptionsVisible ? 'arrow' : 'add-search'} />
          </label>
          <button className="add-search__button" id="add-search__button" onClick={toggleOptions}>
            {searchOptionsVisible ? 'Ausblenden' : 'Erweiterte Suche'}
          </button>
        </div>
        {/* {searchOptionsVisible && (
          <div className="add-search__control-group">
            <label className="add-search__icon add-search__icon--apply" htmlFor="add-search__apply">
              <IconSVG type="apply" />
            </label>
            <button className="add-search__button" id="add-search__apply" onClick={applyFilter}>
              Anwenden
            </button>
          </div>
        )} */}
        {searchOptionsVisible && (
          <div className="add-search__control-group add-search__button--noMargin">
            <label className="add-search__icon add-search__icon--clear" htmlFor="add-search__clear">
              <IconSVG type="clear" />
            </label>
            <button className="add-search__button" id="add-search__clear" onClick={clearFilter}>
              löschen
            </button>
          </div>
        )}
      </div>
      {searchOptionsVisible && (
        <React.Suspense fallback={searchOptionsLoader}>
          <AdditionalSearchOptions
            toggleOptions={toggleOptions}
            filter={filter}
            filterOn={filterOn}
            changeOptionHandler={changeOptionHandler}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default AdditionalSearch;
