/**
 * @type {[string, string][]}
 */
const days = [
  ['Montag', 'Mo'],
  ['Dienstag', 'Die'],
  ['Mittwoch', 'Mi'],
  ['Donnerstag', 'Do'],
  ['Freitag', 'Fr'],
  ['Samstag', 'Sa'],
  ['Sonntag', 'So']
];

export default days;
