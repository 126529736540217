import React, { useState, useCallback } from 'react';
import { IconSVG } from '../IconsSVG';
import { SuspenseLoader } from '../Loader';
import { getRouteHref, getUserCoordinates, isIE, whenDistributorWillOpen } from '../utils';

import './company.scss';

const Modals = React.lazy(() => import('../Modals'));
const isInternetExplorer = isIE();

/**
 * @param {{
 *  routeToDistributorHandler: (distributor: Distributor) => void;
 *  distributor: Distributor;
 *  searchResults: SearchResult[];
 *  setSelfCoordinates: (coords: [number, number]) => void;
 * }} props
 */
function Company({
  toggleItemActive,
  index,
  routeToDistributorHandler,
  setSelfCoordinates,
  distributor,
  searchResults
}) {
  const [modalType, setModalType] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalClosing, setModalClosing] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const isMobile = document.documentElement.clientWidth <= 768;

  const toggleActiveDistributor = useCallback(() => {
    toggleItemActive(distributor);
  }, [distributor, toggleItemActive]);

  /** @type {(type: 1|2|3) => void} */
  const openModal = useCallback(type => {
    setModalOpen(true);
    setModalType(type);
  }, []);

  /** @type {(e: MouseEvent) => void} */
  const openInfoModal = useCallback(
    e => {
      e.stopPropagation();
      openModal(1);
    },
    [openModal]
  );

  /** @type {(e: MouseEvent) => void} */
  const openShareModal = useCallback(
    e => {
      e.stopPropagation();
      openModal(3);
    },
    [openModal]
  );

  /** @type {(e: MouseEvent) => void} */
  const routingHandler = useCallback(
    async e => {
      e.stopPropagation();
      let coordsFrom = [...(searchResults?.[0]?.center || [])];

      if (!coordsFrom.length) {
        setShowLoader(true);

        try {
          coordsFrom = await getUserCoordinates();
          setSelfCoordinates(coordsFrom); // Here we set this coordinates to store for correct display route on map
        } catch (err) {
          openModal(2);
          return;
        } finally {
          setShowLoader(false);
        }
      }

      routeToDistributorHandler(distributor);
    },
    [searchResults, distributor, openModal, routeToDistributorHandler, setSelfCoordinates]
  );

  /** @type {(e: MouseEvent) => void} */
  const closeModal = useCallback(e => {
    e.stopPropagation();
    setModalClosing(true);
    setTimeout(() => {
      setModalClosing(false);
      setModalOpen(false);
    }, 300);
  }, []);

  let isOpen = '';
  let companyClass = '';

  if (!distributor.isOpen) {
    isOpen = `Geschlossen ${whenDistributorWillOpen(distributor.workTime)}`;
    companyClass = 'companies__text--close';
  } else if (distributor.isOpen === 'open') {
    isOpen = 'Offen';
    companyClass = 'companies__text--open';
  } else {
    isOpen = 'Unbekannt';
    companyClass = 'companies__text--unknown';
  }

  let distance = '';
  /** @type {string} */
  let distributorName = distributor.Name;

  if (searchResults?.length && !Number.isNaN(Number(distributor.distance))) {
    distance = `${(distributor.distance * 111.122).toFixed(2)} km entfernt`;
  }

  if (isInternetExplorer && distributorName.length > 62) {
    distributorName = `${distributorName.substring(0, 62)}...`;
  }

  return (
    <div className="companies__item">
      <div
        className="companies__left"
        onClick={toggleActiveDistributor}
        role="button"
        tabIndex={index}
      >
        <h4 className="companies__title">{distributorName}</h4>
        <p className={`companies__text ${companyClass}`}>{isOpen}</p>
        <p className="companies__text companies__nurAlbee">
          {distributor['nur ALbee'] === 't' ? 'nur ALbee' : ''}
        </p>
        <p className="companies__text">{distance}</p>
        <p className="companies__text">{distributor['Straße']}</p>
        <p className="companies__text">
          {distributor['PLZ/Ort']}
          &nbsp;
          {distributor.Ort}
        </p>
      </div>
      <div className="companies__right">
        <button
          className="companies__button companies__button--info"
          aria-label="Info"
          onClick={openInfoModal}
        >
          <IconSVG type="info" />
        </button>
        {isMobile ? (
          <a
            className="companies__button companies__button--route"
            href={getRouteHref(searchResults?.[0]?.center, distributor.path)}
            target="_blank"
            id="mobile-route"
            rel="noopener noreferrer"
          >
            <IconSVG type="route" />
          </a>
        ) : (
          <button
            className="companies__button companies__button--route"
            aria-label="Baue eine Route"
            onClick={routingHandler}
          >
            <IconSVG type="route" />
          </button>
        )}
        <button
          className="companies__button companies__button--send"
          aria-label="Teilen"
          onClick={openShareModal}
        >
          <IconSVG type="send" />
        </button>
      </div>
      {modalOpen && (
        <React.Suspense fallback={<SuspenseLoader />}>
          <Modals
            isOpen={modalOpen}
            onClose={closeModal}
            modalClosing={modalClosing}
            modalType={modalType}
            distributor={distributor}
          />
        </React.Suspense>
      )}
      {showLoader && <SuspenseLoader />}
    </div>
  );
}

export default Company;
