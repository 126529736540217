import { MapLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import { config } from '../../config';

class RoutingMachine extends MapLayer {
  componentDidMount() {
    this.leafletElement = L.Routing.control({
      router: L.Routing.mapbox(config.mapBoxKey),
      position: 'topleft',
      waypoints: [],
      collapsible: true,
      show: true,
      draggableWaypoints: false, // to set draggable option to false
      addWaypoints: false, // disable adding new waypoints to the existing path
      fitSelectedRoutes: false, // don`t zoom
      createMarker() {
        return null;
      }
    });

    this.routeLink = L.control({
      position: 'topleft'
    });
    this.routeLink.onAdd = () => {
      const link = L.DomUtil.create('a', 'route-link');
      if (this.props.from && this.props.to && this.props.from[0] && this.props.to[0]) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          link.href = `https://maps.apple.com/?saddr=${this.props.from[0]},${this.props.from[1]}&daddr=${this.props.to[0]},${this.props.to[1]}`;
        } else {
          link.href = `https://www.google.com/maps/dir/${this.props.from[0]},${this.props.from[1]}/${this.props.to[0]},${this.props.to[1]}/`;
        }
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        // eslint-disable-next-line max-len
        link.innerHTML = `
        <div class="route-link__icon">
          <svg width="21" height="24" viewBox="0 0 21 24" fill="#5E5E5E" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.25 16.76C16.395 16.76 15.63 17.11 15.045 17.6583L7.02375 12.8166C7.08
              12.5483 7.125 12.28 7.125 12C7.125 11.72 7.08 11.4516 7.02375 11.1833L14.955
              6.38831C15.5625 6.97165 16.3612 7.33331 17.25 7.33331C19.1175 7.33331 20.625
              5.76998 20.625 3.83331C20.625 1.89665 19.1175 0.333313 17.25 0.333313C15.3825
              0.333313 13.875 1.89665 13.875 3.83331C13.875 4.11331 13.92 4.38165 13.9762
              4.64998L6.045 9.44498C5.4375 8.86165 4.63875 8.49998 3.75 8.49998C1.8825 8.49998
              0.375 10.0633 0.375 12C0.375 13.9366 1.8825 15.5 3.75 15.5C4.63875 15.5 5.4375
              15.1383 6.045 14.555L14.055 19.4083C13.9987 19.6533 13.965 19.91 13.965
              20.1666C13.965 22.045 15.4388 23.5733 17.25 23.5733C19.0613 23.5733 20.535
              22.045 20.535 20.1666C20.535 18.2883 19.0613 16.76 17.25 16.76Z"/>
          </svg>
        </div>
        `;
      }
      return link;
    };
  }

  // eslint-disable-next-line class-methods-use-this
  createLeafletElement() {}

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { from, to, map } = newProps;
    if (!from || !to || !map) {
      this.leafletElement.remove();
      this.routeLink.remove();
      return;
    }
    if (
      !this.props.from ||
      !this.props.to ||
      Number(this.props.from[0]) !== Number(from[0]) ||
      Number(this.props.from[1]) !== Number(from[1]) ||
      Number(this.props.to[0]) !== Number(to[0]) ||
      Number(this.props.to[1]) !== Number(to[1])
    ) {
      this.leafletElement
        .getPlan()
        .setWaypoints([L.latLng(from[0], from[1]), L.latLng(to[0], to[1])]);
      if (this.props.from) {
        this.leafletElement.addTo(map);
      }
    }
  }

  componentDidUpdate() {
    const { from, to, map } = this.props;
    if (!from || !to || !map) {
      this.leafletElement.remove();
      this.routeLink.remove();
      return;
    }
    this.routeLink.addTo(map);
  }

  render() {
    return null;
  }
}

export default RoutingMachine;
