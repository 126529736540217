import L from 'leaflet';
import PropTypes from 'prop-types';
import { GridLayer, withLeaflet } from 'react-leaflet';
import { config } from '../../config';

require('mapbox-gl');
require('mapbox-gl/dist/mapbox-gl.css');
require('mapbox-gl-leaflet');

class MapBoxGLLayer extends GridLayer {
  constructor(props) {
    super(props);

    this._addLayer = this._addLayer.bind(this);
    this._removeLayer = this._removeLayer.bind(this);
  }

  createLeafletElement(props) {
    const { map } = props.leaflet || this.context;

    map.on('layeradd', e => {
      this._addLayer(e);
    });

    map.on('layerremove', e => {
      this._removeLayer(e);
    });

    return L.mapboxGL(props);
  }

  _addLayer({ layer }) {
    this._layer = layer;
    const { _map } = this._layer;

    if (_map) {
      // Force a resize calculation on the map so that
      // Mapbox GL layer correctly repaints its height after it has been added.
      setTimeout(_map._onResize, 200);
    }
  }

  _removeLayer() {
    this._layer = null;
  }
}

MapBoxGLLayer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  style: PropTypes.string
};

MapBoxGLLayer.defaultProps = {
  style: config.mapBoxStyle,
  accessToken: config.mapBoxKey
};

export default withLeaflet(MapBoxGLLayer);
