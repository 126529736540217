import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import isEqual from 'lodash.isequal';
import {
  searchLocation,
  changeSearchBy,
  toggleOptions,
  addFilter,
  clearSearch,
  toggleCompanies,
  changeAutocompleteResults,
  setCenter
} from '../../reducers/search';
import Search from '../../components/Search';
import AdditionalSearch from '../../components/AdditionalSearch';
import {
  closestToDisplay,
  filterDistributors,
  updateDistributors,
  toDisplay,
  clearDistributorSearch,
  routeToDistributor,
  resetZoomAndCenter,
  chooseDistributor
} from '../../reducers/distributors';
import { mapBoxUrl } from '../../config';
import { dispatchUpdateMap } from '../../components/utils';

function setParams(oldValue = '', value = '') {
  const searchParams = new URLSearchParams(oldValue);
  if (!value) {
    searchParams.delete('p');
  } else {
    searchParams.set('p', value);
  }

  const stringParams = searchParams.toString();
  if (!stringParams) {
    return '';
  }

  return stringParams.replace(/%2C/g, ',').toLowerCase();
}

/**
 * @typedef {object} SearchContainerProps
 * @prop {Distributor[]} distributors
 * @prop {Distributor[]} distributorsFiltered
 * @prop {Distributor} chosenDistributor
 * @prop {Borders} borders
 * @prop {string} searchValue
 * @prop {SearchStore['searchBy']} searchBy
 * @prop {FilterDistributors} filter
 * @prop {boolean} searchOptionsVisible
 * @prop {boolean} companiesVisible
 * @prop {import('redux').Dispatch} dispatch
 * @prop {import('history').Location} location
 * @prop {FilterDistributors} initFilter
 * @prop {import('history').History} history
 *
 * @extends {React.Component<SearchContainerProps>}
 */
class SearchContainer extends React.Component {
  constructor() {
    super();

    /**
     * @type {{
     *  searchValue: string;
     *  searchResultsOpen: boolean;
     *  filter: FilterDistributors;
     * }} */
    this.state = {
      searchValue: '',
      searchResultsOpen: false,
      placeholder: 'Ihre Adresse eingeben',
      filter: {
        onlyOpened: false,
        showUnknown: false,
        volume: {},
        products: {},
        cylinderHeads: {},
        otherProducts: {}
      }
    };
  }

  componentDidMount() {
    this.setState({
      filter: this.props.initFilter
    });
  }

  searchInputHandler = e => {
    const { searchResultsOpen } = this.state;
    const searchValue = e.currentTarget.value;
    let newSearchResultsOpen = searchResultsOpen;
    const minValueLength = this.props.searchBy === 'zip-code' ? 0 : 2;

    if (!searchResultsOpen && searchValue?.length > minValueLength) {
      newSearchResultsOpen = true;
    }

    this.setState({
      searchValue,
      searchResultsOpen: newSearchResultsOpen
    });
  };

  clearSearch = () => {
    if (!this.props.distributors?.length) {
      return;
    }

    if (this.props.searchValue) {
      this.props.dispatch(clearSearch());
      this.props.dispatch(routeToDistributor({}));
      this.props.dispatch(resetZoomAndCenter());
      this.props.dispatch(changeAutocompleteResults([]));
      this.props.dispatch(chooseDistributor({}));
      this.props.dispatch(
        updateDistributors(
          this.props.distributors,
          this.props.distributors,
          this.props.distributors
        )
      );
      this.setState({ searchValue: '' });
    }
  };

  applyFilter = async () => {
    if (!this.props.distributors?.length || !this.props.distributorsFiltered) {
      return;
    }

    const { filter } = this.state;

    if (!isEqual(filter, this.props.filter)) {
      if (this.props.chosenDistributor?.Name) {
        await this.props.dispatch(clearDistributorSearch());
      }

      await this.props.dispatch(addFilter(filter));

      const productsKeys = Object.keys(filter.products);

      if (
        !filter.onlyOpened &&
        !productsKeys.length &&
        !Object.keys(filter.volume).length &&
        !Object.keys(filter.cylinderHeads).length &&
        !Object.keys(filter.otherProducts).length
      ) {
        const url = setParams(this.props.location.search);
        this.props.history.push(`${this.props.location.pathname}?${url}`);

        await this.props.dispatch(
          updateDistributors(
            this.props.distributors,
            this.props.distributors,
            this.props.distributors
          )
        );
      } else {
        if (productsKeys.length) {
          let preParams = '';
          productsKeys.forEach(key => {
            if (filter.products[key]) {
              preParams += `${preParams ? ',' : ''}${key}`;
            }
          });
          const url = setParams(this.props.location.search, preParams);
          this.props.history.push(`${this.props.location.pathname}?${url}`);
        }

        await this.props.dispatch(filterDistributors(this.props.distributors, filter));
      }
    }
  };

  clearFilter = async () => {
    if (!this.props.distributors?.length || !this.props.distributorsFiltered) {
      return;
    }

    if (
      !this.props.filter.onlyOpened &&
      !Object.keys(this.props.filter.volume).length &&
      !Object.keys(this.props.filter.products).length
    ) {
      return;
    }

    /** @type {FilterDistributors} */
    const filter = {
      onlyOpened: false,
      showUnknown: false,
      volume: {},
      products: {},
      cylinderHeads: {},
      otherProducts: {}
    };

    const url = setParams(this.props.location.search);
    this.props.history.push(`${this.props.location.pathname}?${url}`);

    await this.props.dispatch(addFilter(filter));
    await this.props.dispatch(
      updateDistributors(this.props.distributors, this.props.distributors, this.props.distributors)
    );

    if (this.props.borders && Object.keys(this.props.borders).length) {
      this.props.dispatch(toDisplay(this.props.distributorsFiltered, this.props.borders));
    }

    this.setState({ filter });
  };

  searchSubmit = async () => {
    if (!this.props.distributors?.length || !this.props.distributorsFiltered) {
      return;
    }

    this.applyFilter();

    const { searchValue } = this.state;

    if (
      (searchValue && this.props.searchValue && searchValue !== this.props.searchValue) ||
      searchValue
    ) {
      if (this.props.searchBy === 'mapAPI') {
        try {
          const apiRes = await Axios({ method: 'GET', url: mapBoxUrl(searchValue, 1) });

          if (apiRes.data.features?.[0].center[0]) {
            this.successSearchHandler(apiRes.data.features[0], this.props.searchBy);
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      }

      if (this.props.searchBy === 'distributor') {
        const distributorsNameFiltered = [...this.props.distributors].filter(distributor =>
          distributor.Name.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase())
        );

        this.successSearchHandler(
          distributorsNameFiltered[0],
          this.props.searchBy,
          distributorsNameFiltered
        );
      }

      if (this.props.searchBy === 'zip-code') {
        this.successSearchHandler(
          this.props.searchResultsLocal[0],
          this.props.searchBy,
          this.props.distributorsFiltered
        );
      }
    }
  };

  /**
   * @param {SearchResult|Distributor} searchResult
   * @param {SearchStore['searchBy']} type
   * @param {Distributors['distributorsFiltered']} newDistributorsFiltered
   */
  successSearchHandler = async (searchResult, type, newDistributorsFiltered) => {
    if (searchResult === undefined) {
      return;
    }

    const { searchValue } = this.state;
    /** @type {SearchResult} */
    let result;

    switch (type) {
      case 'mapAPI':
        result = {
          ...searchResult,
          center: [searchResult.center[1], searchResult.center[0]]
        };
        break;

      case 'zip-code':
        result = {
          center: [searchResult.latitude, searchResult.longitude],
          place_name_de: searchResult.place
        };
        this.setState({ searchValue: searchResult.zipcode.toString() });
        break;

      case 'distributor':
        result = {
          center: searchResult.path,
          place_name_de: searchResult.Name
        };
        break;

      default:
        break;
    }

    if (this.props.chosenDistributor?.Name) {
      this.props.dispatch(clearDistributorSearch());
    }

    this.props.dispatch(routeToDistributor({}));
    this.props.dispatch(searchLocation([result], searchValue));

    if (this.props.searchBy !== 'mapAPI') {
      await this.props.dispatch(chooseDistributor(searchResult));
      await this.props.scrollToElement(0);
    }

    if (this.props.searchBy === 'mapAPI') {
      await this.props.dispatch(chooseDistributor(searchResult));
      await this.props.scrollToElement(this.props.chosenDistributor.index);
    }

    if (this.props.searchBy === 'zip-code') {
      await this.props.dispatch(
        updateDistributors(
          this.props.distributors,
          newDistributorsFiltered,
          this.props.distributorsToDisplay
        )
      );
    }

    if (searchValue) {
      this.props.dispatch(
        closestToDisplay(this.props.distributors, this.props.distributorsFiltered, [result])
      );
    }

    if (this.props.borders && Object.keys(this.props.borders).length) {
      this.props.dispatch(toDisplay(this.props.distributorsFiltered, this.props.borders));
    }

    this.props.dispatch(setCenter({}));
    this.setState({ searchResultsOpen: false });

    const updateMapCenter = !isEqual(this.props.searchCenter, [])
      ? this.props.searchCenter
      : result.center;

    if (result.center) {
      dispatchUpdateMap(updateMapCenter, this.props.searchZoom);
    }
  };

  /**
   * @param {{resultData: SearchResult|Distributor; type: SearchStore['searchBy']}} clickResult
   */
  autocompleteClick = clickResult => {
    switch (clickResult.type) {
      case 'mapAPI':
        this.setState({ searchValue: clickResult.resultData.place_name });
        break;
      case 'zip-code':
        this.setState({ searchValue: clickResult.resultData.place });
        break;
      default:
        this.setState({ searchValue: clickResult.resultData.Name });
        break;
    }

    this.successSearchHandler(
      clickResult.resultData,
      clickResult.type,
      this.props.distributorsFiltered
    );
  };

  onSearchInputFocus = () => {
    if (this.state.searchValue?.length > 2) {
      this.setState({ searchResultsOpen: true });
    }
  };

  hideSearchResults = e => {
    if (!e.target.classList.contains('search__input')) {
      this.setState({ searchResultsOpen: false });
    }
  };

  toggleOptions = () => {
    this.props.dispatch(toggleOptions(!this.props.searchOptionsVisible));
  };

  toggleCompanies = () => {
    this.props.dispatch(toggleCompanies(!this.props.companiesVisible));
  };

  changeOptionHandler = e => {
    const { dataset: { filter: dataFilter = '' } = {}, id } = e.target;
    const { filter } = this.state;
    /** @type {FilterDistributors} */
    const updatedFilter = {
      onlyOpened: filter.onlyOpened,
      showUnknown: filter.showUnknown,
      volume: { ...filter.volume },
      products: { ...filter.products },
      cylinderHeads: { ...filter.cylinderHeads },
      otherProducts: { ...filter.otherProducts }
    };

    if (
      dataFilter &&
      ['volume', 'products', 'cylinderHeads', 'otherProducts'].includes(dataFilter)
    ) {
      updatedFilter[dataFilter] = updatedFilter[dataFilter] || {};

      if (updatedFilter[dataFilter][id]) {
        delete updatedFilter[dataFilter][id];
      } else {
        updatedFilter[dataFilter][id] = true;
      }
    } else {
      updatedFilter[dataFilter] = !updatedFilter[dataFilter];

      if (dataFilter === 'onlyOpened' && !updatedFilter.onlyOpened && updatedFilter.showUnknown) {
        updatedFilter.showUnknown = false;
      }
    }

    this.setState({ filter: updatedFilter }, this.applyFilter);
  };

  /**
   * @param {SearchStore['searchBy']} value
   */
  setSearchBy = value => {
    this.props.dispatch(changeSearchBy(value));

    switch (value) {
      case 'zip-code':
        this.setState({ placeholder: 'Ihre PLZ eingeben' });
        break;

      case 'distributor':
        this.setState({ placeholder: 'Ihre Verkaufsstelle eingeben' });
        break;

      case 'mapAPI':
        this.setState({ placeholder: 'Ihre Adresse eingeben' });
        break;

      default:
        break;
    }

    this.setState({ searchValue: '' });
  };

  render() {
    return (
      <div>
        <Search
          searchInputHandler={this.searchInputHandler}
          searchValue={this.state.searchValue}
          appSearchValue={this.props.searchValue}
          searchBy={this.props.searchBy}
          setSearchBy={this.setSearchBy}
          searchSubmit={this.searchSubmit}
          clearSearch={this.clearSearch}
          searchOptionsVisible={this.props.searchOptionsVisible}
          companiesVisible={this.props.companiesVisible}
          autocompleteClick={this.autocompleteClick}
          onSearchInputFocus={this.onSearchInputFocus}
          searchResultsOpen={this.state.searchResultsOpen}
          hideSearchResults={this.hideSearchResults}
          placeholder={this.state.placeholder}
        />
        <AdditionalSearch
          toggleOptions={this.toggleOptions}
          toggleCompanies={this.toggleCompanies}
          changeOptionHandler={this.changeOptionHandler}
          filter={this.state.filter}
          filterOn={this.props.filter}
          searchOptionsVisible={this.props.searchOptionsVisible}
          companiesVisible={this.props.companiesVisible}
          applyFilter={this.applyFilter}
          clearFilter={this.clearFilter}
        />
      </div>
    );
  }
}

/** @param {Store} state */
const mapStateToProps = state => {
  return {
    distributors: state.distributors.distributors,
    distributorsFiltered: state.distributors.distributorsFiltered,
    distributorsToDisplay: state.distributors.distributorsToDisplay,
    chosenDistributor: state.distributors.chosenDistributor,
    borders: state.distributors.borders,
    searchValue: state.search.searchValue,
    searchBy: state.search.searchBy,
    searchCenter: state.search.center,
    searchZoom: state.search.zoom,
    filter: state.search.filter,
    searchOptionsVisible: state.search.searchOptionsVisible,
    companiesVisible: state.search.companiesVisible,
    searchResultsLocal: state.search.searchResultsLocal
  };
};

export default connect(mapStateToProps)(withRouter(SearchContainer));
