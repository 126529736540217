import React, { useCallback, useState } from 'react';
import Map from '../../components/Map';
import { SuspenseLoader } from '../../components/Loader';

import './map-container.scss';

const Modals = React.lazy(() => import('../../components/Modals'));

function MapContainer({ scrollToElement }) {
  /** @type {[Distributor, (item: Distributor|null) => void]} */
  const [modalDistributor, setModalDistributor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalClosing, setModalClosing] = useState(false);

  /**
   * @type {(distributor: Distributor) => void} distributor
   */
  const openModal = useCallback(distributor => {
    if (document.documentElement.clientWidth <= 768) {
      setModalDistributor(distributor);
      setModalOpen(true);
    }
  }, []);

  const modalCLose = useCallback(() => {
    setModalClosing(true);
    setTimeout(() => {
      setModalClosing(false);
      setModalOpen(false);
      setModalDistributor(null);
    }, 300);
  }, []);

  return (
    <div className="map-container">
      <Map openDistributorModal={openModal} scrollToElement={scrollToElement} />
      {modalOpen && (
        <React.Suspense fallback={<SuspenseLoader />}>
          <Modals
            isOpen={modalOpen}
            onClose={modalCLose}
            modalClosing={modalClosing}
            modalType={1}
            distributor={modalDistributor}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default MapContainer;
