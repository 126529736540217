import React from 'react';
import { ReactComponent as InfoSVG } from '../../assets/icons/icon-info.svg';
import { ReactComponent as RouteSVG } from '../../assets/icons/icon-route.svg';
import { ReactComponent as SendSVG } from '../../assets/icons/icon-send.svg';
import { ReactComponent as AddSearchSVG } from '../../assets/icons/add-search.svg';
import { ReactComponent as TickSVG } from '../../assets/icons/tick.svg';
import { ReactComponent as CrossSVG } from '../../assets/icons/cross.svg';
import { ReactComponent as FindSVG } from '../../assets/icons/find.svg';
import { ReactComponent as ArrowSVG } from '../../assets/icons/arrow.svg';
import { ReactComponent as AddressSVG } from '../../assets/icons/address.svg';
import { ReactComponent as CallSVG } from '../../assets/icons/call.svg';
import { ReactComponent as EmailSVG } from '../../assets/icons/email.svg';
import { ReactComponent as SiteSVG } from '../../assets/icons/site.svg';
import { ReactComponent as TimeSVG } from '../../assets/icons/time.svg';

// eslint-disable-next-line import/prefer-default-export
export const IconSVG = ({ fill, className, type }) => {
  const svgFill = fill || '#5d5d52';

  switch (type) {
    case 'info': {
      return <InfoSVG fill={fill || '#0288d1'} className={className} />;
    }
    case 'route': {
      return <RouteSVG fill={fill || '#008200'} className={className} />;
    }
    case 'send': {
      return <SendSVG fill={fill || '#d11a1a'} className={className} />;
    }
    case 'add-search': {
      return <AddSearchSVG fill={svgFill} className={className} />;
    }
    case 'apply': {
      return <TickSVG fill={svgFill} className={className} />;
    }
    case 'clear': {
      return <CrossSVG fill={svgFill} className={className} />;
    }
    case 'find': {
      return <FindSVG fill={svgFill} className={className} />;
    }
    case 'arrow': {
      return <ArrowSVG fill={svgFill} className={className} />;
    }
    case 'call': {
      return <CallSVG fill={svgFill} className={className} />;
    }
    case 'address': {
      return <AddressSVG fill={svgFill} className={className} />;
    }
    case 'email': {
      return <EmailSVG fill={svgFill} className={className} />;
    }
    case 'site': {
      return <SiteSVG fill={svgFill} className={className} />;
    }
    case 'time': {
      return <TimeSVG fill={svgFill} className={className} />;
    }
    default:
      return null;
  }
};
