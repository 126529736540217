import { configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import { combineReducers } from 'redux';
import { routerReducer as routing, routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { distributorsReducer } from './reducers/distributors';
import { searchReducer } from './reducers/search';

const history = createBrowserHistory();

const middleware = [...getDefaultMiddleware(), routerMiddleware(history)];

const reducer = combineReducers({
  distributors: distributorsReducer,
  search: searchReducer,
  routing
});

// Create store with reducers and initial state
const store = configureStore({
  reducer,
  middleware
});

export default store;
