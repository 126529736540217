import days from '../constants/days';

/**
 * @param {string[]} filtersKeys
 * @param {Record<string, boolean>} filterOptions
 * @param {Distributor} distributor
 */
function validAddToFilter(filtersKeys, filterOptions, distributor) {
  return filtersKeys.every(key => {
    return filterOptions[key] && distributor[key] === 't';
  });
}

export const dispatchUpdateMap = (center, zoom) => {
  const newZoom = zoom !== '' ? zoom : 8.4;
  const updateMapEvent = new CustomEvent('updateMapEvent', {
    detail: {
      center,
      zoom: newZoom
    }
  });
  document.dispatchEvent(updateMapEvent);
};

/**
 * @param {Distributor[]} distributors
 * @param {FilterDistributors} filterOptions
 * @returns {Distributor[]}
 */
export const applyFilterDistributors = (distributors, filterOptions) => {
  /** @type {Distributor[]} */
  const distributorsFiltered = [];
  /** @type {Distributor[]} */
  const distributorsSecondPart = []; // so as not to sort the array

  const productsKeys = Object.keys(filterOptions.products || {});
  const cylinderHeadsKeys = Object.keys(filterOptions.cylinderHeads || {});
  const otherProductsKeys = Object.keys(filterOptions.otherProducts || {});
  const existProductsFilter = !!productsKeys.length;
  const existCylinderHeadsFilter = !!cylinderHeadsKeys.length;
  const existOtherProductsFilter = !!otherProductsKeys.length;

  const volumeKeys = Object.keys(filterOptions.volume || {});

  distributors.map(distributor => {
    let toSecondPart = false;

    if (filterOptions.onlyOpened) {
      if (!distributor.isOpen) {
        return null;
      }

      if (filterOptions.showUnknown) {
        if (distributor.isOpen === 'unknown') {
          toSecondPart = true;
        }
      } else if (distributor.isOpen !== 'open') {
        return null;
      }
    }

    if (volumeKeys.length) {
      const addToFilter = volumeKeys.every(key => {
        const value = filterOptions.volume[key];

        switch (key) {
          case 'liter5':
            return value && distributor['ALbee 5 Liter'] === 't';
          case 'liter11':
            return value && distributor['ALbee 11 Liter'] === 't';
          default:
            return false;
        }
      });

      if (!addToFilter) {
        return null;
      }
    }

    if (
      (existProductsFilter &&
        !validAddToFilter(productsKeys, filterOptions.products, distributor)) ||
      (existCylinderHeadsFilter &&
        !validAddToFilter(cylinderHeadsKeys, filterOptions.cylinderHeads, distributor)) ||
      (existOtherProductsFilter &&
        !validAddToFilter(otherProductsKeys, filterOptions.otherProducts, distributor))
    ) {
      return null;
    }

    if (toSecondPart) {
      distributorsSecondPart.push(distributor);
    } else {
      distributorsFiltered.push(distributor);
    }

    return null;
  });

  if (distributorsSecondPart.length) {
    distributorsFiltered.push(...distributorsSecondPart);
  }

  return distributorsFiltered;
};

/**
 * @param {Distributor} distA
 * @param {Distributor} distB
 * @returns {number}
 */
export function sortDisplayedDistributors(distA, distB) {
  /* if (distA['AIR LIQUIDE Niederlassung'] === 't') {
    if (distB['AIR LIQUIDE Niederlassung'] === 't') {
      return distA.index - distB.index;
    }

    return -1;
  }

  if (distB['AIR LIQUIDE Niederlassung'] === 't') {
    return 1;
  } */

  return distA.index - distB.index;
}

export function sortDistributorsWithSearch(distA, distB) {
  if (
    Object.prototype.hasOwnProperty.call(distA, 'distance') &&
    Object.prototype.hasOwnProperty.call(distB, 'distance')
  ) {
    return distA.distance - distB.distance;
  }

  return distA.index - distB.index;
}

/**
 * Get distance from point A to point B
 * @param {[string, string]} coordsFrom
 * @param {[number, number]} coordsTo
 * @returns {number}
 */
export function getDistanceFromTo(coordsFrom, coordsTo) {
  const [latFrom, lngFrom] = coordsFrom;
  const [latTo, lngTo] = coordsTo;

  return Math.sqrt((Number(lngFrom) - lngTo) ** 2 + (Number(latFrom) - latTo) ** 2);
}

/**
 * @param {Distributor[]} distributors
 * @param {[number, number]} coordsTo
 */
export function getSortedDistributorsByDistance(distributors, coordsTo) {
  return distributors
    .map(distributor => ({
      ...distributor,
      distance: getDistanceFromTo(distributor.path, coordsTo)
    }))
    .sort((a, b) => a.distance - b.distance);
}

/**
 * Check includes distributor in borders zone
 * @param {[string, string]} distributorPath Distributor coordinates
 * @param {Borders} borders Map borders
 * @returns {boolean}
 */
export function distributorInRange(distributorPath, borders) {
  const { _southWest, _northEast } = borders;

  return (
    betweenNumbers(Number(distributorPath[0]), _southWest.lat, _northEast.lat) &&
    betweenNumbers(Number(distributorPath[1]), _southWest.lng, _northEast.lng)
  );
}

/**
 * @param {[number, number] | undefined} [searchCenter]
 * @param {[string, string]} distributorPath
 * @returns {string}
 */
export function getRouteHref(searchCenter, distributorPath) {
  const [fromLon, fromLat] = searchCenter || [];
  const [toLon, toLat] = distributorPath;
  const { userAgent } = navigator;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    const mapUrl = 'https://maps.apple.com/';

    if (fromLat) {
      return `${mapUrl}?saddr=${fromLon},${fromLat}&daddr=${toLon},${toLat}`;
    }

    return `${mapUrl}?&daddr=${toLon},${toLat}`;
  }

  const mapUrl = 'https://www.google.com/maps/dir/?api=1';

  if (fromLat) {
    return `${mapUrl}&origin=${fromLon},${fromLat}&destination=${toLon},${toLat}`;
  }

  return `${mapUrl}&destination=${toLon},${toLat}`;
}

/**
 * @param {string} distributorLocation
 * @returns {string}
 */
export function getAddress(distributorLocation) {
  return `${window.location.origin}/?d=${distributorLocation}`;
}

/**
 * @param {MouseEvent} event
 * @param {string} distributorLocation
 */
export function shareLocation(event, distributorLocation) {
  event.preventDefault();

  if (navigator.share) {
    navigator
      .share({
        url: getAddress(distributorLocation)
      })
      .catch(console.error); // eslint-disable-line
  }
}

/**
 * Return two date value
 * 1. number of week day
 * 2. Time
 * @param {Date} [date] Date object
 * @returns {[number, number]}
 */
function getDayAndTime(date) {
  const dateObj = date || new Date();

  return [dateObj.getDay(), dateObj.getHours() * 60 + dateObj.getMinutes()];
}

/**
 * Return two next date value
 * 1. number of week day
 * 2. Time
 * @param {Date} [date] Date object
 * @returns {[number, number]}
 */
function getNextDayAndTime(date) {
  const dateObj = date || new Date();
  dateObj.setDate(dateObj.getDate() + 1);

  return getDayAndTime(dateObj);
}

/**
 * Get string when distributor will be open
 * @param {WorkTime} workTime
 * @returns string
 */
export function whenDistributorWillOpen(workTime) {
  const [timeNow, startFirst, endFirst, startSecond] = getWorkTimeIntervalToday(workTime);

  /* distributor don't start work today */
  /* ['0', '0'] must be string */
  if (isInInterval(timeNow, ['0', '0'], startFirst)) {
    return ` - öffnet um ${startFirst[0]}:${startFirst[1]}`;
  }

  /* distributor on lunch break */
  if (isInInterval(timeNow, endFirst, startSecond)) {
    return ` - öffnet um ${startSecond[0]}:${startSecond[1]}`;
  }

  return getFirstDayWithWorkTime(new Date(), workTime);
}

/**
 * Recursive function for get first not empty day's work time
 * @param {Date} date
 * @param {WorkTime} workTime
 * @returns {string}
 */
function getFirstDayWithWorkTime(date, workTime, retry = 6) {
  const [nextDay] = getNextDayAndTime(date);
  const [dayWorkTime, dayName] = getDayWorkTimeByDay(workTime, nextDay);
  const [nextStart] = getWorkTimeIntervals(dayWorkTime);

  if (!nextStart[0] && retry) {
    return getFirstDayWithWorkTime(date, workTime, retry - 1);
  }

  const weekDay = retry === 6 ? ' morgen' : ` am ${dayName}`;

  return ` - öffnet${weekDay}, ${nextStart[0]}:${nextStart[1]}`;
}

/**
 * @param {WorkTime} workTime
 * @returns {[number, [string, string], [string, string], [string, string], [string, string]]}
 */
function getWorkTimeIntervalToday(workTime) {
  const [dayNow, timeNow] = getDayAndTime();
  const [workTimeToday] = getDayWorkTimeByDay(workTime, dayNow);

  return [timeNow, ...getWorkTimeIntervals(workTimeToday)];
}

/**
 * Get workTime intervals by number of week day
 * @param {WorkTime} workTime
 * @param {1 | 2 | 3 | 4 | 5 | 6 | 7} weekDay Number of week day
 * @returns {[[string, string][][], string]}
 */
function getDayWorkTimeByDay(workTime, weekDay) {
  const dayNumber = weekDay || 7; // Hack for case weekDay = 0 (Date.getDay() = 0 for Sunday)
  const [dayName] = days[dayNumber - 1];

  return [workTime[dayName], dayName];
}

/**
 * @param {[string, string][][]} dayWorkTime
 * @returns {[[string, string], [string, string], [string, string], [string, string]]}
 */
function getWorkTimeIntervals(dayWorkTime) {
  const [startFirst, endFirst] = dayWorkTime?.[0] || [];
  const [startSecond, endSecond] = dayWorkTime?.[1] || [];

  return [startFirst, endFirst, startSecond, endSecond];
}

/**
 * @param {WorkTime} workTime
 * @param {boolean} existLeastOneWorkTime
 * @returns {isOpen}
 */
export function isOpenDistributor(workTime, existLeastOneWorkTime) {
  if (!existLeastOneWorkTime) {
    return 'unknown';
  }

  const [currentTime, startFirst, endFirst, startSecond, endSecond] = getWorkTimeIntervalToday(
    workTime
  );
  const inFirstInterval = isInInterval(currentTime, startFirst, endFirst);
  const inSecondInterval = isInInterval(currentTime, startSecond, endSecond);

  if (inFirstInterval || inSecondInterval) {
    return 'open';
  }

  return false;
}

/**
 * @param {number} num
 * @param {[string, string]} start
 * @param {[string, string]} end
 * @returns {boolean}
 */
function isInInterval(num, start = [], end = []) {
  return (
    Boolean(start[0] && start[1] && end[0] && end[1]) &&
    betweenNumbers(num, getCountMinutes(start[0], start[1]), getCountMinutes(end[0], end[1]))
  );
}

/**
 * @param {number | string} hours Hours count
 * @param {number | string} minutes Minutes count
 * @returns {number}
 */
function getCountMinutes(hours, minutes) {
  return Number(hours) * 60 + Number(minutes);
}

/**
 * Check if number `n` is between `a` and `b`
 * @param {number} n number for check
 * @param {number} a left border
 * @param {number} b right border
 * @returns {boolean}
 */
function betweenNumbers(n, a, b) {
  return n >= a && n <= b;
}

/**
 * @returns {Promise<[number, number]>}
 */
export function getUserCoordinates() {
  return new Promise((res, rej) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => res([position.coords.latitude, position.coords.longitude]),
        err => {
          rej(getLocationError(err));
        }
      );
    } else {
      rej('Geolocation is not supported by this browser.');
    }
  });
}

/**
 * @param {PositionError} error
 * @returns {string}
 */
const getLocationError = error => {
  switch (error.code) {
    case error.PERMISSION_DENIED: {
      return 'User denied the request for Geolocation.';
    }
    case error.POSITION_UNAVAILABLE: {
      return 'Location information is unavailable.';
    }
    case error.TIMEOUT: {
      return 'The request to get user location timed out.';
    }
    case error.UNKNOWN_ERROR: {
      return 'An unknown error occurred.';
    }
    default:
      return '';
  }
};

export function isIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.includes('MSIE ');

  // eslint-disable-next-line no-useless-escape
  return msie || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}
