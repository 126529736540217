export const products = [
  'Acetylen',
  'Argon',
  'Ballongas',
  'Formiergas',
  'Propan',
  'Sauerstoff',
  'Schutzgas',
  'Schweißgas',
  'Stickstoff',
  'Trockeneis',
  'Lebensmittelgase'
];

export const cylinderHeads = ['Standard', 'Smartop', 'Altop', 'Exeltop', 'Minitop / Kompakt S'];
