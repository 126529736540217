import { createAction, createReducer } from 'redux-starter-kit';
import zipArray from '../zipSearch.json';

const searchAction = createAction('search/searchLocation');
const addFilterAction = createAction('search/addFilter');
const toggleOptionsAction = createAction('search/toggleOptions');
const toggleCompaniesAction = createAction('search/toggleCompanies');
const setCenterAction = createAction('search/setCenter');
const clearSearchAction = createAction('search/clearSearch');
const autocompleteSearchAction = createAction('search/autocomplete');
const searchLocalAction = createAction('search/localSearch');
const changeSearchByAction = createAction('search/changeSearchBy');

/**
 * @param {SearchStore['searchBy']} value
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const changeSearchBy = value => {
  return dispatch => {
    dispatch(changeSearchByAction(value));
  };
};

/**
 * @callback SearchDistributorsLocalType
 * @param {string} searchValue
 * @param {SearchStore['searchBy']} type
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
/** @type {SearchDistributorsLocalType} */
const searchDistributorsLocal = (searchValue, type = 'distributor') => {
  return (dispatch, getState) => {
    const {
      distributors: { distributors }
    } = getState();

    /** @type {Distributor[]} */
    let foundedDistributors = [];

    if (type === 'distributor') {
      foundedDistributors = distributors.filter(distributor => {
        return distributor.Name.toLocaleUpperCase().includes(searchValue.toLocaleUpperCase());
      });
    }

    if (type === 'zip-code') {
      const arr = [];
      for (let i = 0; i < zipArray.length; i++) {
        if (arr.length === 10) {
          break;
        }
        const el = zipArray[i];
        if (el?.zipcode.toString().includes(searchValue)) {
          arr.push(el);
        }
      }
      foundedDistributors = [...arr];
    }

    dispatch(searchLocalAction(foundedDistributors));
  };
};

/**
 * @callback ChangeAutocompleteResultsType
 * @param {SearchResult[]} searchResults
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
/** @type {ChangeAutocompleteResultsType} */
const changeAutocompleteResults = searchResults => {
  return dispatch => {
    dispatch(autocompleteSearchAction({ searchResults }));
  };
};

/**
 * @param {SearchResult[]} searchResults
 * @param {string} searchValue
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const searchLocation = (searchResults, searchValue) => {
  return dispatch => {
    dispatch(searchAction({ searchResults, searchValue }));
  };
};

/**
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const clearSearch = () => {
  return dispatch => {
    dispatch(clearSearchAction({}));
  };
};

/**
 * @param {FilterDistributors} filterOptions
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const addFilter = filterOptions => {
  return dispatch => {
    dispatch(addFilterAction(filterOptions));
  };
};

/**
 * @param {boolean} searchOptionsVisible
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const toggleOptions = searchOptionsVisible => {
  return dispatch => {
    dispatch(toggleOptionsAction({ searchOptionsVisible }));
  };
};

/**
 * @param {boolean} companiesVisible
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const toggleCompanies = companiesVisible => {
  return dispatch => {
    dispatch(toggleCompaniesAction({ companiesVisible }));
  };
};

/**
 * @param {[number, number]} center
 * @param {number} zoom
 * @returns {(dispatch: import('redux').Dispatch, getState: () => Store) => void}
 */
const setCenter = (center, zoom) => {
  return dispatch => {
    dispatch(setCenterAction({ center, zoom }));
  };
};

/** @type {SearchStore} */
const defaultStore = {
  autocompleteSearchResults: [],
  searchResults: [],
  searchResultsLocal: [],
  searchValue: '',
  searchBy: 'mapAPI',
  filter: {
    onlyOpened: false,
    showUnknown: false,
    volume: {},
    products: {},
    cylinderHeads: {},
    otherProducts: {}
  },
  searchOptionsVisible: false,
  companiesVisible: true,
  center: [],
  zoom: ''
};

/** @type {import('redux').Reducer<SearchStore>} */
const searchReducer = createReducer(defaultStore, {
  [autocompleteSearchAction]: (state, action) => {
    return {
      ...state,
      autocompleteSearchResults: action.payload.searchResults
    };
  },
  [searchLocalAction]: (state, action) => {
    return {
      ...state,
      searchResultsLocal: action.payload
    };
  },
  [changeSearchByAction]: (state, action) => {
    return {
      ...state,
      searchBy: action.payload,
      searchValue: '',
      autocompleteSearchResults: [],
      searchResultsLocal: []
    };
  },
  [searchAction]: (state, action) => {
    return {
      ...state,
      searchResults: action.payload.searchResults,
      searchValue: action.payload.searchValue,
      center: [],
      zoom: ''
    };
  },
  [clearSearchAction]: state => {
    return {
      ...state,
      searchResults: [],
      searchValue: '',
      center: [],
      zoom: ''
    };
  },
  [addFilterAction]: (state, action) => {
    return {
      ...state,
      filter: action.payload
    };
  },
  [toggleOptionsAction]: (state, action) => {
    return {
      ...state,
      searchOptionsVisible: action.payload.searchOptionsVisible,
      companiesVisible: false
    };
  },
  [toggleCompaniesAction]: (state, action) => {
    return {
      ...state,
      companiesVisible: action.payload.companiesVisible,
      searchOptionsVisible: false
    };
  },
  [setCenterAction]: (state, action) => {
    return {
      ...state,
      center: action.payload.center,
      zoom: action.payload.zoom
    };
  }
});

export {
  changeSearchBy,
  searchDistributorsLocal,
  changeAutocompleteResults,
  searchReducer,
  searchLocation,
  toggleOptions,
  addFilter,
  setCenter,
  clearSearch,
  toggleCompanies
};
