import React, { useCallback } from 'react';
import { Marker } from 'react-leaflet';
import { defaultIcon } from '../../MarkerIcon';

/**
 * @param {{
 *  distributor: Distributor;
 *  onClick: (item: Distributor) => void;
 * }} props
 */
function MapMarker({ distributor, onClick }) {
  const onClickHandler = useCallback(() => onClick(distributor), [distributor, onClick]);

  return (
    <Marker
      onClick={onClickHandler}
      position={distributor.path}
      key={distributor.index}
      icon={defaultIcon}
    />
  );
}

export default MapMarker;
