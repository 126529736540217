import React, { useCallback } from 'react';
import DropDownMenu from '../DropDownMenu/DropDownMenu';
import { IconSVG } from '../IconsSVG';
import './search.scss';

const AutocompleteSearch = React.lazy(() => import('../AutocompleteSearch'));

/**
 * @param {{
 *  searchSubmit: () => void;
 *  searchBy: SearchStore['searchBy'];
 *  setSearchBy: (value: string) => void;
 * }} props
 */
function Search(props) {
  const { searchSubmit, searchBy, setSearchBy, placeholder } = props;

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        searchSubmit();
      }
    },
    [searchSubmit]
  );

  return (
    <div className="search">
      <label className="search__icon" htmlFor="find-location">
        <IconSVG type="find" />
      </label>
      {props.appSearchValue && (
        <input className="search__clear" onClick={props.clearSearch} type="submit" value="clear" />
      )}
      <input
        className="search__input"
        onChange={props.searchInputHandler}
        onFocus={props.onSearchInputFocus}
        onClick={props.onSearchInputFocus}
        type="text"
        id="find-location"
        onKeyPress={handleKeyPress}
        value={props.searchValue}
        placeholder={placeholder}
        autoComplete="off"
      />
      <DropDownMenu
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        clearSearch={props.clearSearch}
        items={[
          ['mapAPI', 'Adresse'],
          ['distributor', 'Verkausstelle'],
          ['zip-code', 'PLZ']
        ]}
      />
      <input className="search__button" onClick={searchSubmit} type="submit" value="Suchen" />
      <React.Suspense fallback={<div />}>
        <AutocompleteSearch
          searchBy={searchBy}
          searchValue={props.searchValue}
          autocompleteClick={props.autocompleteClick}
          isOpen={props.searchResultsOpen}
          hideSearchResults={props.hideSearchResults}
        />
      </React.Suspense>
    </div>
  );
}

export default Search;
