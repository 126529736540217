import React, { useCallback, useState } from 'react';

import './cookie-banner.scss';

function CookieBanner() {
  const [altPage, setAltPage] = useState(false);
  const [renderNull, setRenderNull] = useState(false);

  const changePage = useCallback(() => setAltPage(!altPage), [altPage]);

  const cookieAccept = useCallback(() => {
    localStorage.setItem('acceptCookie', 'accept');
    setRenderNull(!renderNull);
  }, [renderNull]);

  const cookieDecline = useCallback(() => {
    localStorage.setItem('acceptCookie', 'decline');
    setRenderNull(!renderNull);
  }, [renderNull]);

  if (localStorage.getItem('acceptCookie') || renderNull) {
    return null;
  }

  return (
    <div className="cookie">
      <div className="cookie__container">
        <h2 className="cookie__header">COOKIE PRÄFERENZ</h2>
        {altPage ? (
          <>
            <h3 className="cookie__subheader">COOKIE DEKLARATION</h3>
            <div className="cookie__item cookie__item--alt">
              Um Ihnen die beste User Experience bieten zu können, optimieren wir unsere Seite mit
              Cookies. Bitte treffen Sie eine Auswahl:
            </div>
            <div className="cookie__item cookie__item--alt">
              <b>Alle Cookies AKZEPTIEREN inkl.</b>
              <br />
              <b>Webtracking Analyse:</b>
              <br />
              Wir verwenden Tracking- & Analytic Cookies von&nbsp;
              <a
                className="cookie__link"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                google
              </a>
              &nbsp;&&nbsp;
              <a
                className="cookie__link"
                href="https://www.hotjar.com/legal/compliance/gdpr-commitment"
                target="_blank"
                rel="noopener noreferrer"
              >
                hotjar
              </a>
            </div>
            <div className="cookie__item cookie__item--alt">
              <b>Cookies ABLEHNEN:</b>
              <br />
              Es werden keine Tracking- & Analytic Cookies gesetzt. Technisch notwendige Cookies
              (google fonts, font awesome) werden ausgeführt.
            </div>
            <div className="cookie__item cookie__item--alt">
              Sie können ihre Cookie-Auswahl jederzeit hier ändern:
              <a
                className="cookie__link"
                href="https://safety-training.at/agb-2/"
                target="_blank"
                rel="noopener noreferrer"
              >
                AGB
              </a>
            </div>
            <input
              className="cookie__link cookie__link--page cookie--smallText"
              type="button"
              onClick={changePage}
              value="ZURÜCK"
            />
          </>
        ) : (
          <>
            <div className="cookie__item">
              Um Ihnen die beste User Experience bieten zu können, optimieren wir unsere Seite mit
              Cookies.
              <br />
              <a
                className="cookie__link"
                href="https://industrie.airliquide.at/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutzerklärung
              </a>
              <br />
              <a
                className="cookie__link"
                href="https://industrie.airliquide.at/impressum"
                target="_blank"
                rel="noopener noreferrer"
              >
                Impressum
              </a>
            </div>
            <div className="cookie__item">
              <div
                className="cookie__button cookie__button--green"
                role="button"
                tabIndex="0"
                onClick={cookieAccept}
              >
                Alle Cookies AKZEPTIEREN inkl. Webtracking Analyse
              </div>
              <div
                className="cookie__button cookie__button--red"
                onClick={cookieDecline}
                role="button"
                tabIndex="0"
              >
                Cookies ABLEHNEN
              </div>
            </div>
            <div className="cookie__item cookie--smallText">
              TREFFEN SIE EINE AUSWAHL UM FORTZUFAHREN.
            </div>
            <input
              className="cookie__link cookie__link--page cookie--smallText"
              type="button"
              onClick={changePage}
              value="COOKIE DEKLARATION"
            />
          </>
        )}
      </div>
    </div>
  );
}

export default CookieBanner;
