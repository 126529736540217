import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { getUserCoordinates } from '../../components/utils';
import SearchContainer from '../SearchContainer';
import { setAndShowSelfCoordinates } from '../../reducers/distributors';
import { SuspenseLoader } from '../../components/Loader';

import './header.scss';

const Modals = React.lazy(() => import('../../components/Modals'));

/**
 * @param {{
 *  distributors: DistributorsStore;
 *  initFilter: FilterDistributors;
 *  dispatch: import('redux').Dispatch;
 * }} props
 */
const Header = ({ distributors, initFilter, dispatch, scrollToElement }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalClosing, setModalClosing] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const findMe = useCallback(async () => {
    if (!distributors?.length) {
      return;
    }

    setShowLoader(true);

    try {
      const position = await getUserCoordinates();
      dispatch(setAndShowSelfCoordinates(position));
    } catch (err) {
      setOpenModal(true);
      setModalMessage(err.toString());
    }

    setShowLoader(false);
  }, [distributors, dispatch]);

  const closeModal = useCallback(() => {
    setModalClosing(true);
    setTimeout(() => {
      setModalClosing(false);
      setOpenModal(false);
      setModalMessage('');
    }, 250);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header__logo-container">
          <h1 className="header__title">Filialfinder</h1>
          <div className="header__logo">
            <a href="https://industrie.airliquide.at" target="_blank" rel="noopener noreferrer">
              <img src="/logo-sm.png" alt="AirLiquide" />
            </a>
          </div>
        </div>
        <SearchContainer initFilter={initFilter} scrollToElement={scrollToElement} />
      </header>
      <input className="locate" onClick={findMe} type="submit" value="" />
      {openModal && (
        <React.Suspense fallback={<SuspenseLoader />}>
          <Modals
            isOpen={openModal}
            onClose={closeModal}
            modalClosing={modalClosing}
            modalType={2}
            modalMessage={modalMessage}
          />
        </React.Suspense>
      )}
      {showLoader && <SuspenseLoader />}
    </>
  );
};

/**
 * @param {Store} state
 */
const mapStateToProps = state => ({
  distributors: state.distributors.distributors
});

export default connect(mapStateToProps)(Header);
