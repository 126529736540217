import React from 'react';
import ReactDOM from 'react-dom';
import PropsTypes from 'prop-types';
import Loader from './Loader';

export default function SuspenseLoader({ node }) {
  return ReactDOM.createPortal(<Loader />, node);
}

SuspenseLoader.propTypes = {
  node: PropsTypes.instanceOf(Element).isRequired
};

SuspenseLoader.defaultProps = {
  node: document.getElementById('root')
};
