import L from 'leaflet';

/* export const filialUnknown = L.icon({
  iconUrl: 'pin-blue.svg',
  iconSize: [38, 40],
  iconAnchor: [19, 40]
});

export const filialOpen = L.icon({
  iconUrl: 'pin-green.svg',
  iconSize: [38, 40],
  iconAnchor: [19, 40]
});

export const filialClose = L.icon({
  iconUrl: 'pin-red.svg',
  iconSize: [38, 40],
  iconAnchor: [19, 40]
}); */

export const defaultIcon = L.icon({
  iconUrl: 'pin-blue.svg',
  iconSize: [38, 40],
  iconAnchor: [19, 40]
});

export const userAddress = L.icon({
  iconUrl: 'map-location.svg',
  iconSize: [55, 55],
  iconAnchor: [27, 50],
  className: 'no-pointer-events search-icon'
});
