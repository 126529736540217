import React from 'react';

import './loader.scss';

export default function Loader() {
  return (
    <div className="loader__loading">
      <div className="loader__loader-item">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
