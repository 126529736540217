import React from 'react';
import { connect } from 'react-redux';
import { Map as LeafletMap, Marker, CircleMarker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { setCenter } from '../../reducers/search';
import { chooseDistributor, clearChooseDistributor, toDisplay } from '../../reducers/distributors';
import RoutingMachine from '../RoutingMachine';
import { userAddress } from '../MarkerIcon';
import { config } from '../../config';
import MapBoxTile from '../MapBoxTile';
import MapMarker from './MapMarkers';

import './map.scss';
import 'react-leaflet-markercluster/dist/styles.min.css';

/**
 * @typedef {object} MapProps
 * @prop {SearchResult[]} searchResults
 * @prop {[number, number]} searchCenter
 * @prop {number} searchZoom
 * @prop {Distributor} chosenDistributor
 * @prop {Distributor[]} distributorsClosest
 * @prop {[number, number]} center
 * @prop {Borders} borders
 * @prop {Distributor[]} distributorsFiltered
 * @prop {[number, number]} routeToDistributorPath
 * @prop {import('redux').Dispatch} dispatch
 * @prop {(item: Distributor) => void} openDistributorModal
 * @prop {(index: number) => void} scrollToElement
 *
 * @extends {React.Component<MapProps>}
 */
class Map extends React.Component {
  constructor() {
    super();

    /** @type {React.RefObject<LeafletMap>} */
    this.map = React.createRef();

    this.updateMap = event => {
      this.map.current.leafletElement.setView(event.detail.center, event.detail.zoom);
    };

    this.moveHandler = this.moveHandler.bind(this);
    this.clearChooseDistributor = this.clearChooseDistributor.bind(this);
    this.keyUpHandler = this.keyUpHandler.bind(this);
  }

  componentDidMount() {
    document.addEventListener('updateMapEvent', this.updateMap);
    document.addEventListener('keyup', this.keyUpHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.keyUpHandler);
    document.removeEventListener('updateMapEvent', this.updateMap);
  }

  /**
   * @param {Borders} borders
   * @param {[number, number]} center
   */
  updateVisibleDistributors = (borders, center) => {
    const { distributorsFiltered, dispatch } = this.props;

    if (distributorsFiltered) {
      dispatch(toDisplay(distributorsFiltered, borders, center));
    }
  };

  /**
   * @param {[number, number]} center
   * @param {number} zoom
   */
  setView = (center, zoom) => {
    if (center && center.length === 2 && zoom >= 1 && zoom <= 18) {
      this.props.dispatch(setCenter(center, zoom));
    }
  };

  // eslint-disable-next-line react/sort-comp
  clearChooseDistributor() {
    this.props.dispatch(clearChooseDistributor());
  }

  /**
   * @param {KeyboardEvent} e
   */
  keyUpHandler(e) {
    if (e.key === 'Escape') {
      this.clearChooseDistributor();
    }
  }

  /**
   * @param {Distributor} distributor
   */
  chooseDistributorHandler = distributor => {
    const { dispatch, chosenDistributor } = this.props;

    if (chosenDistributor.index === distributor.index) {
      this.clearChooseDistributor();
      return;
    }

    this.props.scrollToElement(distributor.index);
    dispatch(chooseDistributor(distributor));
    this.props.openDistributorModal(distributor);
  };

  /**
   * @param {[number, number]} searchLoc
   */
  calcGeo = searchLoc => {
    /** @type {[[number, number], [number, number]]} */
    const bounds = [[...searchLoc], [...searchLoc]]; // don't delete `[...searchLoc]` because this create new array object

    this.props.distributorsClosest.forEach(distributor => {
      if (Number(distributor.path[0]) < bounds[0][0]) {
        bounds[0][0] = distributor.path[0];
      }
      if (Number(distributor.path[1]) < bounds[0][1]) {
        bounds[0][1] = distributor.path[1];
      }
      if (Number(distributor.path[0]) > bounds[1][0]) {
        bounds[1][0] = distributor.path[0];
      }
      if (Number(distributor.path[1]) > bounds[1][1]) {
        bounds[1][1] = distributor.path[1];
      }
    });

    const zoom = this.map.current.leafletElement.getBoundsZoom(bounds) - 0.1;
    const center = [
      (Number(bounds[0][0]) + Number(bounds[1][0])) / 2,
      (Number(bounds[0][1]) + Number(bounds[1][1])) / 2
    ];

    if (center !== this.props.searchCenter && zoom !== this.props.searchZoom) {
      this.setView(center, zoom);
    }
  };

  /**
   * @param {Borders} borders
   */
  calcZoom = borders => {
    const bounds = [
      [borders._southWest.lat, borders._southWest.lng],
      [borders._northEast.lat, borders._northEast.lng]
    ];

    return this.map.current.leafletElement.getBoundsZoom(bounds);
  };

  moveHandler() {
    this.updateVisibleDistributors(
      this.map.current.leafletElement.getBounds(),
      this.map.current.leafletElement.getCenter()
    );
    this.map.current.leafletElement.invalidateSize();
  }

  render() {
    const {
      searchResults = [],
      chosenDistributor,
      distributorsFiltered = [],
      distributorsClosest,
      routeToDistributorPath,
      center,
      borders
    } = this.props;

    let centerMap = config.defaultMapCenter;
    let zoomMap = 7;
    /*
    if (chosenDistributor['Breite und Länge']) {
      centerMap = chosenDistributor.path;
      zoomMap = 18;
    } else  */
    if (Object.keys(borders).length) {
      centerMap = center;
      zoomMap = this.calcZoom(borders);
    }

    const searchLoc = searchResults[0]?.center;

    let disableClustering = 7;
    if (searchLoc && distributorsClosest?.length) {
      this.calcGeo(searchLoc);
    } else {
      disableClustering = 14; // no clustering, when show 10 closest
    }

    const routingMachine = this.map?.current?.leafletElement ? (
      <RoutingMachine
        from={searchLoc}
        to={routeToDistributorPath}
        map={this.map.current.leafletElement}
      />
    ) : null;

    const routeShare =
      routingMachine && searchLoc && routeToDistributorPath ? (
        <a
          className="route-link"
          href={`https://www.google.com/maps/dir/${searchLoc[0]},${searchLoc[1]}/${routeToDistributorPath[0]},${routeToDistributorPath[1]}/`}
        >
          share
        </a>
      ) : null;

    return (
      <LeafletMap
        ref={this.map}
        center={centerMap}
        zoom={zoomMap}
        maxZoom={18}
        minZoom={5}
        zoomSnap={0.1}
        animate
        inertiaMaxSpeed={800}
        easeLinearity={0.5}
        onClick={this.clearChooseDistributor}
        onMoveEnd={this.moveHandler}
        onResize={this.moveHandler}
      >
        <MapBoxTile attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>' />
        <MarkerClusterGroup
          maxClusterRadius={350 / zoomMap}
          disableClusteringAtZoom={disableClustering}
          spiderfyOnMaxZoom={false}
        >
          {distributorsFiltered.map(distributor => (
            <MapMarker
              key={distributor.index}
              distributor={distributor}
              onClick={this.chooseDistributorHandler}
            />
          ))}
        </MarkerClusterGroup>
        {routingMachine}
        {routeShare}
        {searchLoc && <Marker position={searchLoc} icon={userAddress} />}
        {chosenDistributor && chosenDistributor['Breite und Länge'] && (
          <CircleMarker
            onClick={this.clearChooseDistributor}
            center={chosenDistributor.path}
            radius={14}
            color="rgb(0, 15, 150)"
          />
        )}
      </LeafletMap>
    );
  }
}

/**
 * @param {Store} state
 */
const mapStateToProps = state => ({
  searchResults: state.search?.searchResults,
  searchCenter: state.search?.center,
  searchZoom: state.search.zoom,
  chosenDistributor: state.distributors.chosenDistributor,
  distributorsClosest: state.distributors.distributorsClosest,
  center: state.distributors.center,
  borders: state.distributors.borders,
  distributorsFiltered: state.distributors.distributorsFiltered,
  routeToDistributorPath: state.distributors.routeToDistributor?.path
});

export default connect(mapStateToProps)(Map);
