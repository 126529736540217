import * as React from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <a
        className="footer__text"
        href="https://industrie.airliquide.at/impressum"
        target="_blank"
        rel="noopener noreferrer"
      >
        Impressum
      </a>
      <span className="footer__text">&nbsp;|&nbsp;</span>
      <a
        className="footer__text"
        href="https://industrie.airliquide.at/datenschutz"
        target="_blank"
        rel="noopener noreferrer"
      >
        Datenschutz
      </a>
      <span className="footer__text">&nbsp;|&nbsp;</span>
      <a
        className="footer__text"
        href="https://industrie.airliquide.at/kontakt"
        target="_blank"
        rel="noopener noreferrer"
      >
        Kontakt
      </a>
      <div className="footer__logo">
        <a href="https://industrie.airliquide.at" target="_blank" rel="noopener noreferrer">
          <img src="/logo.png" alt="AirLiquide" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
