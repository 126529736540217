import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import CompanyList from '../CompanyList';
import MapContainer from '../MapContainer';
import Header from '../Header';
import CookieBanner from '../../components/CookieBanner';
import Footer from '../../components/Footer';
import {
  filterDistributors,
  loadDistributors,
  toDisplay,
  updateDistributors,
  updateDistributorsBasic
} from '../../reducers/distributors';
import { addFilter, toggleOptions } from '../../reducers/search';
import { config } from '../../config';
import days from '../../constants/days';
import { products as productsList } from '../../constants/filterConstants';
import { applyFilterDistributors, isOpenDistributor } from '../../components/utils';

import './dashboard.scss';

/**
 * @typedef {object} DashboardProps
 * @prop {Distributor[]} distributors
 * @prop {Distributor[]} distributorsFiltered
 * @prop {Distributor[]} distributorsToDisplay
 * @prop {Distributor} chosenDistributor
 * @prop {Borders} borders
 * @prop {import('redux').Dispatch} dispatch
 * @prop {FilterDistributors} filter
 * @prop {import('history').Location} location
 *
 * @extends {React.Component<DashboardProps>}
 */
class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      timer: null,
      initFilter: {},
      loading: true
    };

    /** @type {React.RefObject<import('react-window').FixedSizeList>} */
    this.listRef = React.createRef();
  }

  componentDidMount() {
    /**
     * preset selected filial and filters
     * url/?q=''&q='', q = d || oo || su || v || p
     * d - preset filial
     * d=lat,lng
     * oo - show only opened
     * oo=string
     * string - any string
     * su - show also filial with unknown workschedule, work only with 'oo'
     * su=string
     * string - any string
     * v=value[,value]
     * value - liter5 or liter11, any other will be ignored. One or both through ','
     * p=product[,product,..]
     * product - name of product, for example 'acetylen' or 'Acetylen'. Any quantity through ','
     * wrong products will be ignored
     */
    const searchParams = new URLSearchParams(this.props.location.search);
    const onlyOpened = searchParams.get('oo') && true;
    const showUnknown = onlyOpened && searchParams.get('su') && true;
    const volume = {};
    const toVolume = searchParams.get('v');

    if (toVolume) {
      const validParam = ['liter5', 'liter11'];
      toVolume.split(',').forEach(param => {
        if (validParam.indexOf(param) !== -1) {
          volume[param] = true;
        }
      });
    }

    const products = {};
    const toProducts = searchParams.get('p');

    if (toProducts) {
      toProducts.split(',').forEach(param => {
        const paramFormatted = param[0].toUpperCase() + param.slice(1).toLowerCase();
        if (productsList.indexOf(paramFormatted) !== -1) {
          products[paramFormatted] = true;
        }
      });
    }

    /** @type {FilterDistributors} */
    const filter = {
      onlyOpened,
      showUnknown,
      volume,
      products,
      cylinderHeads: {},
      otherProducts: {}
    };

    if (
      filter.onlyOpened ||
      Object.keys(filter.volume).length ||
      Object.keys(filter.products).length
    ) {
      this.props.dispatch(addFilter(filter));
      this.props.dispatch(toggleOptions(true));
    }

    this.setState({ initFilter: filter, loading: false });

    Axios({
      method: 'get',
      url: `${config.apiUrl}/records`
    })
      .then(async res => {
        const { data } = res;
        if (data.records && data.records.length) {
          /** @type {Distributor[]} */
          const distributors = [];
          const newBorders = {
            _southWest: {
              lat: '',
              lng: ''
            },
            _northEast: {
              lat: '',
              lng: ''
            }
          };

          await data.records.map((distributor, index) => {
            if (
              distributor.Name &&
              distributor['PLZ/Ort'] &&
              distributor.Ort &&
              distributor['Straße'] &&
              distributor['Breite und Länge'].split(',').length === 2
            ) {
              /** @type {[number, number]} */
              const path = distributor['Breite und Länge'].split(',').map(Number);

              let existLeastOneWorkTime = false;
              /** @type {WorkTime} */
              const workTime = {};
              days.forEach(([day]) => {
                if (!existLeastOneWorkTime && distributor[day].trim().length > 0) {
                  existLeastOneWorkTime = true;
                }
                // hack for case when work time with ".", ex 7.20
                // eslint-disable-next-line no-param-reassign
                distributor[day] = distributor[day].replace(/[.]/g, ':');
                workTime[day] = distributor[day].replace(/ /g, '').split(',');
              });

              Object.keys(workTime).forEach(key => {
                const value = workTime[key];
                workTime[key] = value.map(interval =>
                  interval.split('-').map(time => time.split(':'))
                );
              });
              /**
               * distributor.workTime = [
               *  Montag = [] // workTime of Montag
               *    - contain one or two arrays every day
               *    - each such array is the interval of work on that day
               *  Dienstag = [
               *    [], // first interval of the day
               *      - each interval contain two arrays - start and end of workTime
               *    [
               *      [], // start of workTime of that interval
               *        - start(end) of interval is the array, that contain two array - hours and minutes
               *      [
               *        [], // hours
               *        []  // minutes
               *      ]   // end of workTime of that interval
               *    ]
               *  ]
               *  Mittwoch = [
               *    [
               *      [],
               *      [
               *         [],
               *         []
               *      ]
               *    ]
               *  ]
               *  Donnerstag = []
               *  Freitag = []
               *  Samstag = []
               *  Sonntag = []
               * ]
               * distributor.workTime.Montag[0][0][0] = monday[firstInterval][startWork][hours]
               * distributor.workTime.Montag[1][1][1] = monday[secondInterval][endWork][minutes]
               */

              const isOpen = isOpenDistributor(workTime, existLeastOneWorkTime);

              if (
                !newBorders._southWest.lat ||
                Number(newBorders._southWest.lat) > Number(path[0])
              ) {
                newBorders._southWest.lat = Number(path[0]) + 0.2;
              }
              if (
                !newBorders._southWest.lng ||
                Number(newBorders._southWest.lng) > Number(path[1])
              ) {
                newBorders._southWest.lng = Number(path[1]);
              }
              if (
                !newBorders._northEast.lat ||
                Number(newBorders._northEast.lat) < Number(path[0])
              ) {
                newBorders._northEast.lat = Number(path[0]) + 0.2;
              }
              if (
                !newBorders._northEast.lng ||
                Number(newBorders._northEast.lng) < Number(path[1])
              ) {
                // crutch for zoom when map is init
                newBorders._northEast.lng = Number(path[1]) + 0.2;
              }
              distributors.push({
                index,
                path,
                workTime,
                isOpen,
                existLeastOneWorkTime,
                ...distributor
              });
            }

            return null;
          });

          let distributorsFiltered = distributors;
          if (
            filter.onlyOpened ||
            Object.keys(filter.volume).length ||
            Object.keys(filter.products).length
          ) {
            distributorsFiltered = await applyFilterDistributors(distributors, filter);
          }

          const filialCoords = searchParams.get('d');
          let chosenDistributor = {};

          if (filialCoords) {
            chosenDistributor = this.chooseDistributor(distributorsFiltered, filialCoords);
          }

          this.props.dispatch(
            loadDistributors(distributors, distributorsFiltered, newBorders, chosenDistributor)
          );

          const timer = setInterval(this.updateDistributorsIsOpen, 60 * 1000); // launch timer for update open/close status
          this.setState({ timer });
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.distributors.length &&
      this.props.distributors.length &&
      this.props.chosenDistributor?.Name
    ) {
      this.scrollToElement(this.props.chosenDistributor.index);
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      this.clearInterval(this.state.timer);
    }
  }

  /**
   * @param {Distributor[]} distributors
   * @param {string} filialCoords
   */
  chooseDistributor = (distributors, filialCoords) => {
    const item = distributors.find(elem => elem['Breite und Länge'] === filialCoords);

    if (item && Object.prototype.hasOwnProperty.call(item, 'index')) {
      return item;
    }

    return {};
  };

  updateDistributorsIsOpen = () => {
    const { distributors, changes } = this.checkIsOpen(this.props.distributors);

    if (changes) {
      if (!this.props.filter.onlyOpened) {
        // just update isOpen in arrays
        const { distributors: distributorsFiltered } = this.checkIsOpen(
          this.props.distributorsFiltered
        );
        const { distributors: distributorsToDisplay } = this.checkIsOpen(
          this.props.distributorsToDisplay
        );

        this.props.dispatch(
          updateDistributors(distributors, distributorsFiltered, distributorsToDisplay)
        );
      } else {
        this.props.dispatch(updateDistributorsBasic(distributors));
        this.props.dispatch(filterDistributors(distributors, this.props.filter));

        if (this.props.borders && Object.keys(this.props.borders).length) {
          this.props.dispatch(toDisplay(this.props.distributorsFiltered, this.props.borders));
        }
      }
    }
  };

  /**
   * @param {Distributor[]} distributorsToCheck
   */
  checkIsOpen = distributorsToCheck => {
    let changes = false;

    const distributors = distributorsToCheck.map(distributor => {
      const isOpen = isOpenDistributor(distributor.workTime, distributor.existLeastOneWorkTime);

      if (isOpen !== distributor.isOpen && !changes) {
        changes = true;
      }

      return { ...distributor, isOpen };
    });

    return { distributors, changes };
  };

  /**
   * @param {number} index Index of distributor (not index in array)
   */
  scrollToElement = index => {
    if (!this.listRef.current) {
      setTimeout(() => this.scrollToElement(index), 450);
      return;
    }

    const distributorIndex = this.props.distributorsToDisplay.findIndex(el => el.index === index);

    const checkDistIndex = distributorIndex + 1 && distributorIndex;
    const scrollItem = index === 0 ? index : checkDistIndex;

    this.listRef.current.scrollToItem(scrollItem, 'smart');
  };

  render() {
    return (
      <div className="dashboard">
        <CookieBanner />
        {!this.state.loading && (
          <Header initFilter={this.state.initFilter} scrollToElement={this.scrollToElement} />
        )}
        <div className="main">
          <CompanyList listRef={this.listRef} />
          <MapContainer scrollToElement={this.scrollToElement} />
        </div>
        <Footer />
      </div>
    );
  }
}

/**
 * @param {Store} state
 */
const mapStateToProps = state => ({
  distributors: state.distributors.distributors,
  distributorsFiltered: state.distributors.distributorsFiltered,
  distributorsToDisplay: state.distributors.distributorsToDisplay,
  chosenDistributor: state.distributors.chosenDistributor,
  borders: state.distributors.borders,
  filter: state.search.filter
});

export default connect(mapStateToProps)(withRouter(Dashboard));
