import React, { useState, useCallback, useEffect, useRef } from 'react';

import './DropDownMenu.scss';

/**
 * @param {{
 *  items: [string, string][];
 *  searchBy: SearchStore['searchBy'];
 *  setSearchBy: (value: SearchStore['searchBy']) => void;
 * }} props
 */
function DropDownMenu({ items = [], setSearchBy, searchBy, clearSearch }) {
  const [activeKey, activeValue] = items.find(([key]) => key === searchBy) || [];
  /** @type {React.MutableRefObject<HTMLElement>} */
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  /** @type {(value: SearchStore['searchBy']) => void} */
  const onClickHandler = useCallback(
    searchByValue => {
      if (searchBy !== searchByValue) {
        setSearchBy(searchByValue);
      }

      setIsOpen(false);
    },
    [setSearchBy, searchBy]
  );

  const handleClick = useCallback(e => {
    if (dropdownRef?.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClick);
    } else {
      document.removeEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen, handleClick]);

  return (
    <div className="dropdown" ref={dropdownRef} onChange={clearSearch}>
      <button className="dropdown-toggle" tabIndex={0} role="menu" onClick={toggleDropdown}>
        {`Suche nach: ${activeValue}`}
      </button>
      <div className={`dropdown-menu${isOpen ? ' show' : ''}`}>
        {items.map(([key, text]) => (
          <div
            className={`dropdown-item${activeKey === key ? ' active' : ''}`}
            key={key}
            tabIndex={-1}
            role="menuitem"
            onClick={() => onClickHandler(key)}
          >
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DropDownMenu;
