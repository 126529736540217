import React, { memo } from 'react';
import memoize from 'memoize-one';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, areEqual } from 'react-window';
import { connect } from 'react-redux';
import Company from '../../components/Company';
import {
  chooseDistributor,
  clearChooseDistributor,
  routeToDistributor,
  setAndShowSelfCoordinates
} from '../../reducers/distributors';

import './company-list.scss';

/**
 * @type {React.NamedExoticComponent<{
 *  data: {
 *   height: number;
 *   items: Distributor[];
 *   toggleItemActive: (item: Distributor) => void;
 *   activeIndex: number;
 *   width: number;
 *   searchResults: SearchResult[];
 *   routeToDistributorHandler: (item: Distributor) => void;
 *   setSelfCoordinates: (coords: [number, number]) => void;
 *  };
 *  index: number;
 *  style: any;
 * }>}
 */
const Row = memo(({ data, index, style }) => {
  // Data passed to List as "itemData" is available as props.data
  const {
    items,
    toggleItemActive,
    activeIndex,
    searchResults,
    routeToDistributorHandler,
    setSelfCoordinates
  } = data;
  const item = items[index];

  return (
    <div
      style={style}
      className={`companies__container ${
        activeIndex === item.index ? 'companies__container--choosen' : ''
      }`}
    >
      <Company
        style={style}
        distributor={item}
        key={index}
        tabIndex={index}
        toggleItemActive={toggleItemActive}
        routeToDistributorHandler={routeToDistributorHandler}
        setSelfCoordinates={setSelfCoordinates}
        searchResults={searchResults}
      />
    </div>
  );
}, areEqual);
Row.displayName = 'Row';

// This helper function memoizes incoming props,
// To avoid causing unnecessary re-renders pure Row components.
// This is only needed since we are passing multiple props with a wrapper object.
// If we were only passing a single, stable value (e.g. items),
// We could just pass the value directly.
const createItemData = memoize(
  (
    items,
    toggleItemActive,
    activeIndex,
    searchResults,
    setSelfCoordinates,
    routeToDistributorHandler
  ) => ({
    items,
    toggleItemActive,
    activeIndex,
    searchResults,
    setSelfCoordinates,
    routeToDistributorHandler
  })
);

// and "toggleItemActive" is a function that updates an item's state.
/**
 * @param {{
 *  listRef: React.RefObject<List>;
 *  height: number;
 *  items: Distributor[];
 *  toggleItemActive: (e: MouseEvent, item: Distributor) => void;
 *  activeIndex: number;
 *  width: number;
 *  searchResults: SearchResult[];
 *  routeToDistributorHandler: (item: Distributor) => void;
 * }} props
 */
function Companies({
  listRef,
  height,
  items,
  toggleItemActive,
  activeIndex,
  width,
  searchResults,
  setSelfCoordinates,
  routeToDistributorHandler
}) {
  // Bundle additional data to list items using the "itemData" prop.
  // It will be accessible to item renderers as props.data.
  // Memoize this data to avoid bypassing shouldComponentUpdate().
  const itemData = createItemData(
    items,
    toggleItemActive,
    activeIndex,
    searchResults,
    setSelfCoordinates,
    routeToDistributorHandler
  );

  return (
    <List
      height={height}
      itemCount={items.length}
      itemData={itemData}
      itemSize={166} // must be equal companies__container form company.scss
      width={width}
      ref={listRef}
    >
      {Row}
    </List>
  );
}

/**
 * @typedef {object} CompanyListProps
 * @prop {Distributor[]} distributors
 * @prop {Distributor[]} distributorsFiltered
 * @prop {Distributor} [chosenDistributor]
 * @prop {number} [routeToDistributorIndex]
 * @prop {SearchResult[]} [searchResults]
 * @prop {FilterDistributors} filter
 * @prop {boolean} companiesVisible
 * @prop {React.RefObject<List>} listRef
 * @prop {import('redux').Dispatch} dispatch
 *
 * @extends {React.Component<CompanyListProps>}
 */
class CompanyList extends React.Component {
  /**
   * @param {Distributor} distributor
   */
  chooseDistributorHandler = distributor => {
    const { dispatch, chosenDistributor } = this.props;

    if (chosenDistributor.index === distributor.index) {
      dispatch(clearChooseDistributor());
    } else {
      dispatch(chooseDistributor(distributor));
    }
  };

  /**
   * @param {Distributor} distributor
   */
  routeToDistributorHandler = distributor => {
    if (this.props.searchResults?.length) {
      if (this.props.routeToDistributorIndex === distributor.index) {
        this.props.dispatch(routeToDistributor({}));
      } else {
        this.props.dispatch(routeToDistributor(distributor));
      }
    }
  };

  /**
   * @param {[number, number]} coords
   */
  setSelfCoordinates = coords => {
    this.props.dispatch(setAndShowSelfCoordinates(coords));
  };

  render() {
    const {
      distributors = [],
      distributorsFiltered = [],
      distributorsToDisplay = [],
      chosenDistributor,
      filter,
      searchResults,
      companiesVisible
    } = this.props;

    let distributorsNotExist = false;

    if (
      (filter.onlyOpened ||
        Object.keys(filter.volume).length ||
        Object.keys(filter.products).length) &&
      distributors.length &&
      !distributorsFiltered.length
    ) {
      distributorsNotExist = true;
    }

    return (
      <div className={`companies ${companiesVisible ? '' : 'companies--hide'}`}>
        {distributorsToDisplay.length > 0 && (
          <AutoSizer className="autosizer-container" style={{ width: '100%', height: '100%' }}>
            {({ height, width }) => (
              <Companies
                height={height}
                items={distributorsToDisplay}
                toggleItemActive={this.chooseDistributorHandler}
                activeIndex={chosenDistributor.index}
                width={width}
                searchResults={searchResults}
                routeToDistributorHandler={this.routeToDistributorHandler}
                setSelfCoordinates={this.setSelfCoordinates}
                listRef={this.props.listRef}
              />
            )}
          </AutoSizer>
        )}
        {distributorsNotExist && (
          <div className="companies__not-found">
            Es konnten keine Filialen mit den ausgewählten Filtern gefunden werden.
          </div>
        )}
      </div>
    );
  }
}

/**
 * @param {Store} state
 */
const mapStateToProps = state => ({
  distributors: state.distributors.distributors,
  distributorsFiltered: state.distributors.distributorsFiltered,
  distributorsToDisplay: state.distributors.distributorsToDisplay,
  chosenDistributor: state.distributors.chosenDistributor,
  routeToDistributorIndex: state.distributors.routeToDistributor?.index,
  filter: state.search.filter,
  searchResults: state.search.searchResults,
  companiesVisible: state.search.companiesVisible
});

export default connect(mapStateToProps)(CompanyList);
